'use client'
import React, { FC, ReactNode } from 'react'
import { AssistantV2 } from 'storefront-modules/assistant'
import { Box } from '@chakra-ui/react'
import { ProductListItemBuy } from 'storefront-modules/products'
import appConfig from '#/src/appConfig'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { DeliveryDTO, Option } from 'ecosystem'

interface AppComponentProps {
  children: ReactNode
  deliveries?: Option<DeliveryDTO[]>
}

const AppComponent: FC<AppComponentProps> = ({ children, deliveries }) => {
  const { handleAddToCart } = useCustomCart()
  const { handleProductRequest } = useProductRequest()

  return (
    <>
      {children}
      {process.env.NEXT_PUBLIC_AI_ASSISTANT_ENABLED === 'true' && (
        <AssistantV2
          paths={{
            chat: '/api/assistant/v2/chat',
            thread: '/api/assistant/v2/thread',
            textToSpeech: '/api/assistant/v2/text-to-speech',
            settings: '/api/assistant/settings',
            products: appConfig.paths.productsUrlPath
          }}
          components={{
            price: {
              render: ({ product }) => (
                <>
                  {!!product.originalProduct && (
                    <Box
                      sx={{
                        '.product-list-item-buy': {
                          '&__button-wrapper': {
                            position: 'static'
                          }
                        }
                      }}
                      w="100%">
                      <ProductListItemBuy
                        priceRequestButtonProps={{
                          onClick: (e) => e.stopPropagation()
                        }}
                        buttonProps={{
                          onClick: (e) => e.stopPropagation()
                        }}
                        price={{
                          price: product.originalProduct.price,
                          originalPrice: product.originalProduct.originalPrice
                        }}
                        product={product.originalProduct}
                        deliveryOptions={deliveries ?? null}
                        productsUrlPath={appConfig.paths.productsUrlPath}
                        onBuy={(item) => {
                          handleAddToCart({ ...item, qty: '1' })
                        }}
                        onProductRequest={handleProductRequest}
                      />
                    </Box>
                  )}
                </>
              )
            }
          }}
        />
      )}
    </>
  )
}

export default AppComponent
