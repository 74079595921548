import { useEffect, useState } from 'react'

interface SpeechAnimationSrcProps {
  isLoading?: boolean
  isPlaying?: boolean
  isRecording?: boolean
  isPlayingThumb?: boolean
}

const useSpeechAnimationSrc = ({
  isLoading,
  isPlaying,
  isRecording,
  isPlayingThumb
}: SpeechAnimationSrcProps) => {
  const [animationSrc, setAnimationSrc] = useState<string | null>(null)

  useEffect(() => {
    switch (true) {
      case isLoading:
        setAnimationSrc('https://lottie.host/e1069ba0-d7f0-4639-a43f-6a9374c2c379/VSstlf7Zw3.json')
        break
      case isPlayingThumb:
        setAnimationSrc('https://lottie.host/f199918b-d992-4353-963e-792f98598e6b/hxvaNreltD.json')
        break
      case isPlaying:
        setAnimationSrc('https://lottie.host/643316cc-d552-40bc-9b64-9a2cef52cac3/4GBDtvqQFT.json')
        break
      case isRecording:
        // https://lottie.host/6bbc4e12-50e3-49fb-8ce7-9a3dc18218d6/slL5zkHJZv.json -- Alternative
        setAnimationSrc('https://lottie.host/2d5c3cc5-d336-4cf2-99db-755f4613c622/nG8ZMWOi8X.json')
        break
      default:
        setAnimationSrc(null)
    }
  }, [isLoading, isPlaying, isPlayingThumb, isRecording])

  return animationSrc
}

export default useSpeechAnimationSrc
