import { type FC, type ReactNode, useCallback } from 'react'
import { Grid, GridItem, HStack, Icon, IconButton } from '@chakra-ui/react'
import type { AssistantMessageProductDTO, Option } from 'ecosystem'
import { PiHeadphonesFill } from 'react-icons/pi'
import { CgClose } from 'react-icons/cg'
import useConversationalMode from '../hooks/useConversationalMode'
import ChatMessages from './ChatMessages'
import { useAssistantContext } from './asssistantContext'
import ChatTextInput from './ChatTextInput'
import { ConversationalModeStatus } from './types'
import ConversationalMode from './ConversationalMode'

interface ChatProps {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  isExpanded: boolean
  onSpeak: (
    text: string,
    {
      onAudioStart,
      onAudioEnd
    }: {
      onAudioStart: () => void
      onAudioEnd: () => void
    }
  ) => Promise<void>
  onSpeakError: Option<string>
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
  isConversationalModeEnabled?: boolean
}

const Chat: FC<ChatProps> = ({
  isPlayingSpeech,
  renderProductsComponent,
  onSpeak,
  isLoadingSpeech,
  isConversationalModeEnabled
}) => {
  const stateUpdater = useAssistantContext()
  const {
    inputValue: value,
    setInputValue,
    isLoading,
    handleSend,
    isInit,
    inputRef,
    history,
    onSpeakAudioRef
  } = stateUpdater

  const sendMessage = useCallback(
    async (text: string) => {
      if (isLoading || !isInit) {
        return
      }

      setInputValue('')
      await handleSend(text)
    },
    [handleSend, isInit, isLoading, setInputValue]
  )

  const onTranscriptSucess = useCallback(
    async (transcript: string) => {
      await sendMessage(transcript)
    },
    [sendMessage]
  )

  const onTranscriptError = useCallback(async (error: string) => {
    await new Promise((resolve) => {
      // eslint-disable-next-line -- Intended log in console
      console.error('Chat error', error)
      resolve(true)
    })
  }, [])

  const { isSupported, status, activate, deactivate, isOperational, labels, isBusy, isProcessing } =
    useConversationalMode({
      onResult: onTranscriptSucess,
      onResultError: onTranscriptError,
      history,
      onResponse: onSpeak
    })

  return (
    <Grid
      className="ai-widget__chat"
      position="relative"
      gap="1"
      h="full"
      templateColumns="repeat(1, 1fr)"
      templateRows="repeat(12, 1fr)"
      w="full">
      {isOperational ? <ConversationalMode {...{ status, isProcessing }} /> : null}

      <GridItem colSpan={1} rowSpan={11}>
        <ChatMessages
          statusLabel={labels}
          isPlayingSpeech={isPlayingSpeech}
          isLoadingSpeech={isLoadingSpeech}
          onSpeak={async (text) =>
            await onSpeak(text, {
              onAudioEnd: () => {
                // no implementation
              },
              onAudioStart: () => {
                // no implementation
              }
            })
          }
          renderProductsComponent={renderProductsComponent}
          h="full"
          w="full"
        />
      </GridItem>

      <GridItem
        pb={4}
        pt={4}
        px={2}
        as={HStack}
        align="center"
        colSpan={1}
        rowSpan={1}
        position="relative">
        <ChatTextInput
          ref={inputRef}
          onUpdate={setInputValue}
          onSend={sendMessage}
          value={value}
          isLoading={isLoading}
          isInit={isInit}
        />

        {isSupported && isConversationalModeEnabled ? (
          <IconButton
            id="voice-assistant-trigger"
            aria-label="Voice Assistant"
            borderRadius="full"
            onClick={() => {
              if (status === ConversationalModeStatus.Inactive) {
                onSpeakAudioRef?.current?.pause()
                activate()
              } else {
                void deactivate()
              }
            }}
            icon={
              <Icon
                as={status === ConversationalModeStatus.Active ? CgClose : PiHeadphonesFill}
                boxSize={4}
              />
            }
            size="sm"
            zIndex={2}
            colorScheme={isOperational ? 'red' : undefined}
            isDisabled={
              (isOperational && isBusy) || status === ConversationalModeStatus.NotInitialized
            }
          />
        ) : null}
      </GridItem>
    </Grid>
  )
}

export default Chat
