'use client'
import { FC, ReactElement } from 'react'
import SlideOutCategories, {
  ISlideOutCategoriesProps
} from './slideouts/SlideOutCategories/SlideOutCategories'
import SlideOutMobileMenu, { ISlideOutMobileMenuProps } from './slideouts/SlideOutMobileMenu'
import SlideOutMoreCategories, {
  ISlideOutMoreCategoriesProps
} from './slideouts/SlideOutMoreCategories'
import MobileMenuSlideOutFooter from './slideouts/MobileMenuSlideOutFooter'
import SlideOutCustomerOrderDetail, {
  SlideOutCustomerOrderDetailProps
} from './slideouts/SlideOutCustomerOrderDetail'
import SlideOutMoreFilters, { ISlideOutMoreFiltersProps } from './slideouts/SlideOutMoreFilters'
import SlideOutMoreFilters1, { ISlideOutMoreFilters1 } from './slideouts/SlideOutMoreFilters1'
import CustomSlideOutSearch from './slideouts/CustomSlideOutSearch'
import SlideOutMoreFiltersSearch, {
  ISlideOutMoreFiltersSearch
} from './slideouts/SlideOutMoreFiltersSearch'

type SlideOutComponentDictionary = Record<SlideOutComponentType, ReactElement>
type SlideOutFooterDictionary = Record<SlideOutFooterType, ReactElement>

interface ISlideOutComponentProps {
  componentId: SlideOutComponentType | null
  extraArgs: unknown
}

interface ISlideOutFooterProps {
  footerId?: SlideOutFooterType | null
}

export const SlideOutComponent: FC<ISlideOutComponentProps> = ({ componentId, extraArgs }) => {
  if (!componentId) return null
  // components are added to this dictionary, and to access them, you will need to use useSlideOut hook to pass the
  // componentId to openSlideOut
  const dictionary: SlideOutComponentDictionary = {
    default: <div>DEV: no component selected</div>,

    categories: <SlideOutCategories {...(extraArgs as ISlideOutCategoriesProps)} />,

    mobileMenu: <SlideOutMobileMenu {...(extraArgs as ISlideOutMobileMenuProps)} />,

    moreCategories: <SlideOutMoreCategories {...(extraArgs as ISlideOutMoreCategoriesProps)} />,

    moreFilters: <SlideOutMoreFilters {...(extraArgs as ISlideOutMoreFiltersProps)} />,

    moreFilters1: <SlideOutMoreFilters1 {...(extraArgs as ISlideOutMoreFilters1)} />,

    moreFiltersSearch: <SlideOutMoreFiltersSearch {...(extraArgs as ISlideOutMoreFiltersSearch)} />,

    customerOrderDetail: (
      <SlideOutCustomerOrderDetail {...(extraArgs as SlideOutCustomerOrderDetailProps)} />
    ),

    search: <CustomSlideOutSearch />
  }

  return dictionary[componentId] ?? dictionary.default
}

export const SlideOutFooter: FC<ISlideOutFooterProps> = ({ footerId }) => {
  if (!footerId) return null
  // components are added to this dictionary, and to access them, you will need to use useSlideOut hook to pass the
  // footerId to openSlideOut
  const dictionary: SlideOutFooterDictionary = {
    default: <div />,

    mobileMenu: <MobileMenuSlideOutFooter />
  }

  return (footerId ? dictionary[footerId] : null) ?? dictionary.default
}
