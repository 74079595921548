import type { FC } from 'react'
import { Flex, VStack } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { ConversationalModeStatus } from './types'
import useSpeechAnimationSrc from './useSpeechAnimationSrc'

const LottiePlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false
  }
)

interface ConversationalModeProps {
  status: ConversationalModeStatus
  isProcessing: boolean
}

const ConversationalMode: FC<ConversationalModeProps> = ({ status, isProcessing }) => {
  const animationSrc = useSpeechAnimationSrc({
    isPlaying: status === ConversationalModeStatus.Responding,
    isRecording: status === ConversationalModeStatus.Recording
  })

  const showBox = !isProcessing && status !== ConversationalModeStatus.Error

  return (
    <VStack
      zIndex="modal"
      w="90px"
      h="90px"
      right="2.5%"
      bottom="16%"
      rounded="md"
      shadow={showBox ? 'sm' : 'none'}
      position="absolute"
      bg={showBox ? 'background.default' : 'transparent'}>
      <VStack spacing={0} flex={1} w="full" bg="transparent">
        <Flex alignItems="center" justifyContent="center" w="full" h="full" bg="transparent">
          {animationSrc ? (
            <LottiePlayer
              autoplay
              loop
              src={animationSrc}
              style={{ height: '100%', width: '80%' }}
            />
          ) : null}
        </Flex>
      </VStack>
    </VStack>
  )
}

export default ConversationalMode
