import { Box, IconButton } from '@chakra-ui/react'
import GrandPopover from 'ui/common/GrandPopover'
import { useRef } from 'react'
import useGetCachedRequest from 'api/utils/next/useGetCachedRequest'
import type { AssistantDTO } from 'ecosystem'
import { CgClose } from 'react-icons/cg'
import { Avatar } from '../Avatar'
import { useAssistantSize } from '../useAssistantSize'
import { type AssistantContextState } from '../types'
import { BG_GRADIENT } from '../constants'
import Main from './Main'

interface AssistantProps {
  paths: {
    chat: string
    settings: string
    thread: string
    products: string
    textToSpeech: string
  }
  components?: AssistantContextState['components']
}

export const AssistantV2 = (props: AssistantProps) => {
  const { paths, components } = props
  const { data: assistantSettings, isLoading } = useGetCachedRequest<AssistantDTO>(paths.settings)
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    isExpanded,
    toggleAssistant: onToggle,
    closeAssistant: onClose,
    isOpen
  } = useAssistantSize()

  if (isLoading) {
    return null
  }

  if (!assistantSettings) {
    return null
  }

  return (
    <Box
      bottom="0px"
      left="0px"
      position="fixed"
      sx={{
        '.chakra-popover__popper': {
          zIndex: 3
        },
        '.chakra-popover__content': {
          border: 'none'
        }
      }}
      zIndex={3}>
      <GrandPopover
        borderRadius={16}
        {...{ isOpen }}
        contentProps={{
          maxWidth: isExpanded ? '62em' : '22em',
          width: 'calc(100vw - 44px)',
          outlineColor: 'none',
          shadow: 'xl',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        initialFocusRef={inputRef}
        placement="top-end"
        target={
          <Box bottom="20px" left="20px" position="fixed" zIndex={3}>
            <IconButton
              className="ai-widget__main-btn"
              rounded="full"
              overflow="hidden"
              aria-label={assistantSettings.name ?? 'AI assistant'}
              bg="transparent"
              bgGradient={isOpen ? BG_GRADIENT : 'red'}
              color="text.light"
              p={0}
              icon={
                isOpen ? (
                  <CgClose size={25} />
                ) : (
                  <Avatar
                    w={12}
                    h={12}
                    imageProps={{
                      src: assistantSettings.imgUrl,
                      objectFit: 'contain',
                      w: 'full',
                      h: 'full',
                      sizes: '100px'
                    }}
                  />
                )
              }
              minW={undefined}
              onClick={onToggle}
              size="lg"
              w={12}
            />
          </Box>
        }
        title="">
        <Main
          inputRef={inputRef}
          onClose={onClose}
          paths={props.paths}
          settings={assistantSettings}
          components={components}
        />
      </GrandPopover>
    </Box>
  )
}
