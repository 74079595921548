import { VStack } from '@chakra-ui/react'
import type { CustomComponentImplementation } from 'react-formatge/dist/esm/types'
import type { FC } from 'react'
import type { OptionSelectionProps } from './OptionSelection'
import OptionSelection from './OptionSelection'

type OptionSelectProps = Partial<OptionSelectionProps> &
  CustomComponentImplementation<string> & {
    items: {
      label: string
      value: string
    }[]
  }

const OptionSelect: FC<OptionSelectProps> = ({
  value,
  defaultValue,
  onUpdateValue,
  items,
  onChange,
  ...props
}) => (
  <VStack align="flex-start" w="full">
    <OptionSelection
      bg="background.default"
      borderColor="text.default"
      borderWidth="1px"
      data-initial={defaultValue}
      onChange={(e) => {
        onUpdateValue?.(e.target.value)
        onChange?.(e)
      }}
      options={[...items]}
      {...props}
      value={value || ''}
    />
  </VStack>
)

export default OptionSelect
