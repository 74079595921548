import { ConversationalModeStatus } from '../components/types'

export enum ConversationalModeTransitions {
  Initialize = 'Initialize',
  Activate = 'Activate',
  Inactivate = 'Inactivate',
  StartRecording = 'StartRecording',
  StartProcessing = 'StartProcessing',
  StartResponding = 'StartResponding',
  Error = 'Error',
  Reset = 'Reset',
  FinishResponding = 'FinishResponding',
  FinishRecording = 'FinishRecording',
  FinishProcessing = 'FinishProcessing',
  Cleanup = 'Cleanup'
}

type StateMachine<S extends string, T extends string> = {
  [K in S]: {
    [U in T]?: S
  }
}

const stateMachine: StateMachine<ConversationalModeStatus, ConversationalModeTransitions> = {
  [ConversationalModeStatus.NotInitialized]: {
    [ConversationalModeTransitions.Initialize]: ConversationalModeStatus.Inactive,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.Inactive]: {
    [ConversationalModeTransitions.Activate]: ConversationalModeStatus.Active,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.Active]: {
    [ConversationalModeTransitions.StartResponding]: ConversationalModeStatus.Responding,
    [ConversationalModeTransitions.StartRecording]: ConversationalModeStatus.Recording,
    [ConversationalModeTransitions.Inactivate]: ConversationalModeStatus.Inactive,
    [ConversationalModeTransitions.Error]: ConversationalModeStatus.Error,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.Recording]: {
    [ConversationalModeTransitions.FinishRecording]: ConversationalModeStatus.Active,
    [ConversationalModeTransitions.StartProcessing]: ConversationalModeStatus.Processing,
    [ConversationalModeTransitions.Error]: ConversationalModeStatus.Error,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized,
    [ConversationalModeTransitions.Inactivate]: ConversationalModeStatus.Inactive
  },
  [ConversationalModeStatus.Processing]: {
    [ConversationalModeTransitions.FinishProcessing]: ConversationalModeStatus.ProcessingResponse,
    [ConversationalModeTransitions.Error]: ConversationalModeStatus.Error,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.ProcessingResponse]: {
    [ConversationalModeTransitions.StartResponding]: ConversationalModeStatus.Responding,
    [ConversationalModeTransitions.Error]: ConversationalModeStatus.Error,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.Responding]: {
    [ConversationalModeTransitions.FinishResponding]: ConversationalModeStatus.Inactive,
    [ConversationalModeTransitions.Error]: ConversationalModeStatus.Error,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  },
  [ConversationalModeStatus.Error]: {
    [ConversationalModeTransitions.Inactivate]: ConversationalModeStatus.Inactive,
    [ConversationalModeTransitions.Reset]: ConversationalModeStatus.Active,
    [ConversationalModeTransitions.Cleanup]: ConversationalModeStatus.NotInitialized
  }
}

export const transitionStatus = (
  state: ConversationalModeStatus,
  action: ConversationalModeTransitions
): ConversationalModeStatus => {
  const nextState = stateMachine[state]?.[action]
  if (nextState === undefined) {
    return state
  }
  return nextState
}
