import { useMemo } from 'react'
import { type AssistantMessageFE } from 'ecosystem'

export const useLastAssistantMessage = (history: AssistantMessageFE[] | null) => {
  return useMemo(() => {
    if (!history?.length) {
      return ''
    }

    const lastMessage = history[history.length - 1]

    if (lastMessage.role === 'assistant') {
      return lastMessage.text
    }

    for (let i = history.length - 2; i >= 0; i--) {
      if (history[i].role === 'assistant') {
        return history[i].text
      }
    }

    return ''
  }, [history])
}
