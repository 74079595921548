import { type AssistantTextToSpeechRequestDTO } from 'ecosystem'
import { type MutableRefObject } from 'react'

interface TextToSpeechProps {
  fetchUrl: string
  payload: AssistantTextToSpeechRequestDTO
  audioCache: Map<string, string>
  audioRef: MutableRefObject<HTMLAudioElement | null>
  onPlay: () => void
  onEnd: () => void
}

class AudioController {
  private audioRef: MutableRefObject<HTMLAudioElement | null>
  private readonly onPlay: () => void
  private readonly onEnd: () => void

  constructor(
    audioRef: MutableRefObject<HTMLAudioElement | null>,
    onPlay: () => void,
    onEnd: () => void
  ) {
    this.audioRef = audioRef
    this.onPlay = onPlay
    this.onEnd = onEnd
  }

  public async playAudio(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.audioRef.current) {
        this.audioRef.current.pause()
        this.audioRef.current.currentTime = 0
      }

      const audio = new Audio(url)
      this.audioRef.current = audio

      const onAudioPlay = () => this.onPlay()
      const onAudioEnd = () => {
        this.onEnd()
        audio.removeEventListener('playing', onAudioPlay)
        audio.removeEventListener('pause', onAudioEnd)
        audio.removeEventListener('ended', onAudioEnd)
        resolve()
      }
      const onAudioError = (error: Event) => {
        this.onEnd()
        audio.removeEventListener('playing', onAudioPlay)
        audio.removeEventListener('pause', onAudioEnd)
        audio.removeEventListener('ended', onAudioEnd)
        reject(new Error(`Audio playback error: ${String(error)}`))
      }

      audio.addEventListener('playing', onAudioPlay)
      audio.addEventListener('pause', onAudioEnd)
      audio.addEventListener('ended', onAudioEnd)
      audio.addEventListener('error', onAudioError)

      audio.play().catch((error: unknown) => onAudioError(error as Event))
    })
  }
}

class AudioFetcher {
  private readonly fetchUrl: string

  constructor(fetchUrl: string) {
    this.fetchUrl = fetchUrl
  }

  public async fetchAudio(payload: AssistantTextToSpeechRequestDTO): Promise<string> {
    const res = await fetch(this.fetchUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (res.ok && res.body) {
      const reader = res.body.getReader()
      const chunks: Uint8Array[] = []
      let done = false

      while (!done) {
        const { value, done: readerDone } = await reader.read()
        if (value) {
          chunks.push(value)
        }
        done = readerDone
      }

      const audioBlob = new Blob(chunks, { type: 'audio/mp3' })
      return URL.createObjectURL(audioBlob)
    }
    throw new Error('Failed to fetch the audio')
  }
}

export async function textToSpeech({
  fetchUrl,
  onPlay,
  audioRef,
  audioCache,
  payload,
  onEnd
}: TextToSpeechProps) {
  const { text } = payload

  const audioController = new AudioController(audioRef, onPlay, onEnd)

  if (audioCache.has(text)) {
    const cachedAudioUrl = audioCache.get(text)
    if (cachedAudioUrl) {
      await audioController.playAudio(cachedAudioUrl)
      return
    }
  }

  const audioFetcher = new AudioFetcher(fetchUrl)
  const audioUrl = await audioFetcher.fetchAudio(payload)
  audioCache.set(text, audioUrl)

  await audioController.playAudio(audioUrl)
}
