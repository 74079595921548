import type { NextApiRequest, NextApiResponse } from 'next'
import type {
  AssistantV2ChatResponseDTO,
  AssistantV2ThreadRequestDTO,
  AssistantV2ThreadResponseDTO,
  ErrorRes,
  Language,
  OldProductDTO
} from 'ecosystem'
import storefrontApi from 'api/storefront-api'
import { useLocaleCredentials as setLocaleCredentials } from 'api'
import { getSnowplowSessionIdFromCookies } from 'shared-utils'

export const getAssistantV2ChatResponseHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AssistantV2ChatResponseDTO | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let assistantChatResponse: AssistantV2ChatResponseDTO | null

  const rawCookies = Object.entries(req.cookies)
    .map(([key, value]) => `${key}=${value}`)
    .join('; ')
  const sessionId = getSnowplowSessionIdFromCookies(rawCookies)

  try {
    assistantChatResponse = await storefrontApi.assistantV2.getAssistantV2ChatResponse({
      ...req.body,
      sessionId
    })
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
    return
  }

  if (!assistantChatResponse) {
    res.status(502).json({ error: 'Response is unavailable' })
    return
  }

  if (assistantChatResponse.products.length) {
    await Promise.allSettled(
      assistantChatResponse.products.map((product) =>
        storefrontApi.products.productBySlug(product.slug)
      )
    )
      .then((responses) => {
        return responses.reduce<Record<string, OldProductDTO>>((acc, result) => {
          if (result.status === 'fulfilled' && result.value) {
            acc[result.value.slug] = result.value
          }
          return acc
        }, {})
      })
      .then((resObject) => {
        assistantChatResponse.products.forEach((product) => {
          product.originalProduct = resObject[product.slug]
        })
      })
  }

  try {
    res.status(200).json(assistantChatResponse)
  } catch (error) {
    res.status(500).json({ error: (error as Error).message })
  }
}

export const getAssistantV2ThreadHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AssistantV2ThreadResponseDTO | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'GET') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let assistantChatResponse: AssistantV2ThreadResponseDTO | null

  try {
    assistantChatResponse = await storefrontApi.assistantV2.getAssistantV2Thread(
      req.query as AssistantV2ThreadRequestDTO
    )
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
    return
  }

  if (!assistantChatResponse) {
    res.status(502).json({ error: 'Response is unavailable' })
    return
  }

  try {
    res.status(200).json(assistantChatResponse)
  } catch (error) {
    res.status(500).json({ error: (error as Error).message })
  }
}

export const assistantV2TextToSpeechResponseHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)

  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let assistantTextToSpeechResponse: ReadableStream | null

  try {
    assistantTextToSpeechResponse = await storefrontApi.assistantV2.textToSpeech(req.body)
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
    return
  }

  if (!assistantTextToSpeechResponse) {
    res.status(502).json({ error: 'Response is unavailable' })
    return
  }

  try {
    await createStreamResponse(res, assistantTextToSpeechResponse, 200)
  } catch (error) {
    res.status(500).json({ error: (error as Error).message })
  }
}

async function createStreamResponse(res: NextApiResponse, stream: ReadableStream, status = 200) {
  res.status(status)
  res.setHeader('Access-Control-Allow-Headers', 'Content-Type')
  res.setHeader('Content-Type', 'application/octet-stream')

  const reader = stream.getReader()
  const writer = res

  const pushStream = async () => {
    let done = false
    while (!done) {
      const { value, done: readDone } = await reader.read()
      if (readDone) {
        done = true
        writer.end()
        break
      }
      if (value) {
        writer.write(Buffer.from(value))
      }
    }
  }

  await pushStream()
}
