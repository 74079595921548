import { FC } from 'react'
import { Avatar, CloseButton, HStack, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandText } from 'ui'
import { useSlideOut } from '#/src/custom/controllers'
import { OrderDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { commaSeparator } from 'shared-utils'
import { CustomerOrderItem, OrderGroupItem } from 'storefront-modules/account'

export interface SlideOutCustomerOrderDetailProps {
  order: OrderDTO
}

const SlideOutCustomerOrderDetail: FC<SlideOutCustomerOrderDetailProps> = ({ order }) => {
  const { closeSlideOut } = useSlideOut()
  return (
    <VStack spacing={2} w="full">
      <CloseButton alignSelf="flex-end" onClick={closeSlideOut} />
      <GrandHeading textAlign="right" title="Information" />
      <CustomerOrderItem customerOrder={order} />

      <GrandHeading textAlign="right" title="Leveransadress" />
      <OrderGroupItem label="Adress 1" value={order.shippingAddress?.address1} />
      <OrderGroupItem label="Adress 2" value={order.shippingAddress?.address2} />
      <OrderGroupItem label="Postnummer" value={order.shippingAddress?.postalCode} />
      <OrderGroupItem label="Postnummer" value={order.shippingAddress?.postalCode} />
      <OrderGroupItem label="Attention" value={order.shippingAddress?.attention} />
      <OrderGroupItem label="Mobil" value={order.shippingAddress?.mobilePhone} />

      <GrandHeading textAlign="right" title="Din beställning" />
      <VStack w="full">
        {order.items?.map((item, index) => {
          const { mainImageUrl } = getProductImages(item.product.medias)
          return (
            <VStack key={index} w="full">
              <HStack w="full">
                <Avatar flexGrow={0} src={mainImageUrl} />
                <GrandText fontWeight="bold">{item.product.name}</GrandText>
                <GrandText>{item.quantity} st.</GrandText>
                <GrandText>{commaSeparator(item.unitPrice)} SEK</GrandText>
              </HStack>
            </VStack>
          )
        })}
      </VStack>
    </VStack>
  )
}

export default SlideOutCustomerOrderDetail
