export const getMessagesElement = () => document.getElementById('assistant-messages')
export const handleScrollBottom = () => {
  const chatMessages = getMessagesElement()

  if (!chatMessages) return

  chatMessages.scrollTop = chatMessages.scrollHeight
}

export const waitForElement = (selector: string, callback: (element: HTMLElement) => void) => {
  const element = document.querySelector<HTMLElement>(selector)

  if (element) {
    callback(element)
    return
  }

  const observer = new MutationObserver((_mutations, obs) => {
    const e = document.querySelector<HTMLElement>(selector)
    if (e) {
      callback(e)
      obs.disconnect()
    }
  })

  observer.observe(document.body, {
    childList: true,
    subtree: true
  })
}
