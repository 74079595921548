import { FC, ReactElement } from 'react'
import { Box } from '@chakra-ui/react'

export interface IModalConfirmationProps {
  component: ReactElement | string
}

const ModalConfirmation: FC<IModalConfirmationProps> = () => {
  return (
    <Box h="100%" w="100%">
      <Box pb={10} px={5} textAlign="center" w="100%">
        Tack för att du skickade in din förfrågan! Vi kommer att kontakta dig så snart som möjligt.
      </Box>
    </Box>
  )
}

export default ModalConfirmation
