import storefrontApi from 'api/storefront-api'
import {
  type AssistantChatResponseDTO,
  type AssistantDTO,
  type ErrorRes,
  type Language,
  type Option
} from 'ecosystem'
import type { NextApiRequest, NextApiResponse } from 'next'
import { useLocaleCredentials as setLocaleCredentials } from 'api'

export * from './assistantV2'

export const getAssistantChatResponseHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AssistantChatResponseDTO | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let assistantChatResponse: AssistantChatResponseDTO | null

  try {
    assistantChatResponse = await storefrontApi.assistant.getAssistantChatResponse(req.body)
  } catch (error: unknown) {
    res.status(500).json({ error: (error as Error)?.message })
    return
  }

  if (!assistantChatResponse) {
    res.status(404).json({ error: 'Response is unavailable' })
    return
  }

  try {
    res.status(200).json(assistantChatResponse)
  } catch (error) {
    res.status(404).json({ error: (error as Error).message })
  }
}

export const getAssistantSettingsHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AssistantDTO | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'GET') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  let assistantSettings: Option<AssistantDTO>

  try {
    assistantSettings = await storefrontApi.store
      .getStore({ query: ['settings'] })
      .then((store) => store.settings?.assistant ?? null)
  } catch (error: unknown) {
    res.status(500).json({ error: (error as Error)?.message })
    return
  }

  if (!assistantSettings) {
    res.status(404).json({ error: 'Assistant settings are not found' })
    return
  }

  try {
    res.status(200).json(assistantSettings)
  } catch (error) {
    res.status(404).json({ error: (error as Error).message })
  }
}
